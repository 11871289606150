<template>

    <v-card
        height=220
    >
        <v-card-title
            class="body-1 font-weight-black grey lighten-2"
        >
            {{nombre}}
        </v-card-title>

        <v-card-text>
            <!-- ------------------------------------------------------------
            - Los detalles de la escala ----
            ----------------------------------------------------------------- -->
            <v-row class="text-center">
                <v-col class="px-1" cols="4" v-for="trn in datosTurno" :key="trn.id">
                    <div><span class="caption font-weight-black" :style="{color:trn.turnoColor}">{{trn.turnoNombre}}</span></div>

                    <!-- Botón (cuando no hay) -->
                    <div v-if="filtraTurno(trn.turno).valor==null">
                        <v-btn icon :color="trn.turnoColor" @click="cargaValora(trn.turno)">
                            <v-icon>mdi-playlist-plus</v-icon>
                        </v-btn>
                    </div>
                    <div v-if="filtraTurno(trn.turno).valor!=null">
                        <!-- Bandera (escala). Se debe sustituir por un gráfico duando corresponda -->
                        <div>
                            <v-icon
                                v-if="valora!='reactividad' && valora!='dolor' && valora!='ramsay'"
                                :color="filtraTurno(trn.turno).color"
                                large
                                @click="abreDetalles(trn.turno)"
                            >
                                mdi-flag
                            </v-icon>
                        </div>
                        <!-- Imagen de la escala de reactividad (ojitos) -->
                        <div v-if="valora =='reactividad'" class="d-flex justify-center">
                            <v-img
                                height="25"
                                contain
                                :src="filtraTurno(trn.turno).imagen"
                                class="mb-3"
                                @click="abreDetalles(trn.turno)"
                            />
                        </div>

                        <!-- Imagen de la escala de dolor (caritas) -->
                        <div v-if="valora =='dolor'" class="d-flex justify-center">
                            <v-img
                                height="40"
                                contain
                                :src="filtraTurno(trn.turno).imagen"
                                class="mb-2"
                                @click="abreDetalles(trn.turno)"
                            />
                        </div>

                        <!-- Imagen RAMSAY -->
                        <div v-if="valora =='ramsay'" class="d-flex justify-center">
                            <v-img
                                height="40"
                                contain
                                :src="filtraTurno(trn.turno).imagen"
                                class="mb-2"
                                @click="abreDetalles(trn.turno)"
                            />
                        </div>

                        <v-icon
                            small
                            color="red lighten-3"
                            v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                            @click="borraEntrada(filtraTurno(trn.turno).id)"
                        >
                            mdi-eraser
                        </v-icon>
                    </div>
                    <!-- Leyenda (el valor) -->
                    <div>{{filtraTurno(trn.turno).valor}}</div>
                    <div>{{filtraTurno(trn.turno).text}}</div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props:{
        nombre: String,
        valora: String,
        datos:Array,
    },
    data: ()=>({
        datosTurno:[
            {turno:'mat', turnoNombre:'Matutino', turnoColor:'blue'},
            {turno:'ves', turnoNombre:'Vespertino', turnoColor:'green'},
            {turno:'noc', turnoNombre:'Nocturno', turnoColor:'red'},
        ]
    }),
    methods:{
        abreDetalles(de){
            let dat = this.datos.find( dt => dt.turno === de )
            this.$swal.fire({
                html:
                    '<p>' + (dat.observaciones?dat.observaciones:'') +'</p>'+
                    '<p style="font-size:12px;">Registrado: '+dat.dateCreated+' por '+dat.creado+'</p>'
            })
            //console.log(dat)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraEscala',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.$emit('borrado')
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },
        borraEntrada(id){
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },

        cargaValora(turno){
            this.$emit('cargaValora', this.valora, turno)
        },
        // -------------------------------------------------
        // Etiqueta a mostrar de acuerdo al tipo de escala
        // -------------------------------------------------
        filtraTurno(tu){
            let valor = null
            let texto = null
            let imagenRuta = ''
            let color = 'black'
            let entradaId = 0

            let dat = this.datos.find( dt => dt.turno === tu )
            if (dat){
                entradaId = dat.id
            }
            switch (this.valora){
                case 'general':
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Mejorado'
                                color = 'green'
                                break

                            case 2:
                                texto = 'Delicado'
                                color = 'orange'
                                break

                            case 3:
                                texto = 'Grave'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Muy grave'
                                color = 'black'
                                break
                        }
                        valor = dat.valor
                    }
                break

                case 'reactividad':
                    if (dat){
                        switch(dat.valor){
                            case 1:
                                texto = 'Isocoria'
                                imagenRuta='/images/escalas/isocoria.jpg'
                                break

                            case 2:
                                texto = 'Midriasis'
                                imagenRuta='/images/escalas/midriasis.jpg'
                                break

                            case 3:
                                texto = 'Miosis'
                                imagenRuta='/images/escalas/miosis.jpg'
                                break

                            case 4:
                                texto = 'Anisocoria'
                                imagenRuta='/images/escalas/anisocoria.jpg'
                                break
                        }
                        valor = dat.valor
                    }
                    break

                case 'conciencia':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.ocular + con.verbal + con.motor
                        valor = esteValor
                        if (esteValor <= 8){
                            texto = 'Trauma severo'
                            color = 'red'
                        }
                        if (esteValor > 8 && esteValor <= 12){
                            texto = 'Trauma moderado'
                            color = 'orange'
                        }
                        if (esteValor > 12){
                            texto = 'Trauma Leve'
                            color = 'green'
                        }
                    }
                    break

                case 'npass':
                    if (dat){
                        //console.log('----')
                        let con = JSON.parse(dat.valorString)
                        //console.log(con)
                        // {"motor":0,"verbal":0,"ocular":0,"sdg":3,"llanto":2,"comportamiento":-1,"facial":0,"muscular":2,"signos":-2,"correccionEdad":0}
                        let esteValor = con.sdg + con.llanto + con.comportamiento + con.facial + con.muscular + con.signos
                        //esteValor = esteValor - 2
                        //console.log(esteValor)
                        valor = esteValor
                        if (esteValor < 0){
                            texto = 'Sedado'
                            color = 'orange'
                        }
                        if (esteValor == 0){
                            texto = 'Normal'
                            color = 'green'
                        }
                        if (esteValor > 0){
                            texto = 'Con dolor'
                            color = 'red'
                        }
                    }
                    break

                case 'ramsay':
                    if(dat){
                        valor = dat.valor

                        if (dat.valor == 1){
                            imagenRuta='/images/escalas/ramsay-01.png'
                            texto='Despierto, ansioso'
                        }
                        if (dat.valor == 2){
                            imagenRuta='/images/escalas/ramsay-02.png'
                            texto='Despierto, tranquilo'
                        }
                        if (dat.valor == 3){
                            imagenRuta='/images/escalas/ramsay-03.png'
                            texto='Dormido a órdenes'
                        }
                        if (dat.valor == 4){
                            imagenRuta='/images/escalas/ramsay-04.png'
                            texto='Somnoliento'
                        }
                        if (dat.valor == 5){
                            imagenRuta='/images/escalas/ramsay-05.png'
                            texto='Dormido con respuesta'
                        }
                        if (dat.valor == 6){
                            imagenRuta='/images/escalas/ramsay-06.png'
                            texto='Profundamente dormido'
                        }
                    }
                    break

                case 'silverman':
                    if (dat){
                        let sil = JSON.parse(dat.valorString)
                        let esteValor = sil.torax + sil.ic + sil.xifo + sil.aleteo + sil.quejido
                        valor = esteValor

                        if (esteValor == 0){
                            texto = 'Sin dific.'
                            color = 'green'
                        }

                        if (esteValor > 0 && esteValor <= 3){
                            texto = 'Dific. leve'
                            color = 'blue'
                        }

                        if (esteValor > 3 && esteValor <= 6){
                            texto = 'Dific. moderada'
                            color = 'orange'
                        }

                        if (esteValor > 6){
                            texto = 'Dific. severa'
                            color = 'red'
                        }
                    }
                    break

                case 'caidas':
                    if (dat){
                        let valorDat = 0
                        let con = dat.valorString.split(',')
                        con.forEach(c2 => {
                            valorDat = valorDat + parseInt(c2.split('-')[1])
                        });
                        valor = valorDat
                        if (valorDat <= 1){
                            texto = 'Bajo'
                            color = 'green'
                        }
                        if (valorDat > 1 && valorDat <= 3){
                            texto = 'Mediano'
                            color = 'orange'
                        }
                        if (valorDat > 3){
                            texto = 'Alto'
                            color = 'red'
                        }
                    }
                    break

                case 'upp':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let valDat = con.percepcion + con.humedad + con.actividad+con.movilidad+con.nutricion+con.roce
                        valor = valDat
                        if (valDat < 12){
                            texto = 'Alto'
                            color = 'red'
                        }
                        if (valDat >=12  && valDat <= 14){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (valDat > 14){
                            texto = 'Bajo'
                            color = 'green'
                        }
                    } 
                    break

                case 'dolor':
                    if(dat){
                        valor = dat.valor
                        if (dat.valor == 0){
                            texto='Sin'
                            imagenRuta = '/images/escalas/dolor-1.png'
                        }
                        if (dat.valor >0 && dat.valor <=2){
                            texto='Poco'
                            imagenRuta='/images/escalas/dolor-2.png'
                        }
                        if (dat.valor >2 && dat.valor <=4){
                            texto='Moderado'
                            imagenRuta='/images/escalas/dolor-3.png'
                        }
                        if (dat.valor >4 && dat.valor <=6){
                            texto='Fuerte'
                            imagenRuta='/images/escalas/dolor-4.png'
                        }
                        if (dat.valor >6 && dat.valor <=8){
                            texto='Muy fuerte'
                            imagenRuta='/images/escalas/dolor-4.png'
                        }
                        if (dat.valor >8){
                            texto='Insoportable'
                            imagenRuta='/images/escalas/dolor-6.png'
                        }
                    }
                    break

                case 'dolorPipp':
                    if (dat){
                        let con = JSON.parse(dat.valorString)
                        let esteValor = con.edad + con.comportamiento + con.fc + con.sat + con.cejas + con.ojos + con.labio
                        valor = esteValor
                        if (esteValor >= 12){
                            texto = 'Intenso'
                            color = 'red'
                        }
                        if (esteValor > 6 && esteValor < 12){
                            texto = 'Moderado'
                            color = 'orange'
                        }
                        if (esteValor <=6){
                            texto = 'Leve o sin'
                            color = 'green'
                        }
                    } 
                    break

                case 'flebitis':
                    if (dat){
                        valor = dat.valor
                        switch(dat.valor){
                            case 0:
                                texto = 'Sin'
                                color = 'green'
                                break

                            case 1:
                                texto = 'Posible'
                                color = 'orange'
                                break

                            case 2:
                                texto = 'Inicio'
                                color = 'red'
                                break

                            case 3:
                                texto = 'Media'
                                color = 'red'
                                break

                            case 4:
                                texto = 'Avanzado'
                                color = 'red'
                                break

                            case 5:
                                texto = 'Tromboflebitis'
                                color = 'red'
                                break
                        }
                    }
                    break
            }
            return {valor:valor, text:texto, color:color, imagen:imagenRuta, id:entradaId}
        },
        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },
    },
}
</script>

<style>

</style>